<template>
  <svg
    id="svg2" version="1.1" width="422.17334" height="152.34666" viewBox="0 0 422.17334 152.34666"
    sodipodi:docname="Arrow - Paranza.eps" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs6" />
    <g
      id="g8" inkscape:groupmode="layer" inkscape:label="ink_ext_XXXXXX"
      transform="matrix(1.3333333,0,0,-1.3333333,0,152.34667)"
    >
      <g id="g10" transform="scale(0.1)">
        <path
          id="path12" d="M 3128.83,562.82 H 30"
          style="fill:none;stroke:currentColor;stroke-width:60;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        />
        <path
          id="path14" d="M 2724.39,1112.57 3128.83,562.82 2724.39,30"
          style="fill:none;stroke:currentColor;stroke-width:60;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        />
      </g>
    </g>
  </svg>
</template>
